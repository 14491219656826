import React, {  } from 'react'
import './App.css';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect
} from 'react-router-dom'
import Footer from './components/Footer';
import Dexie from 'dexie';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { ThemeProvider } from '@fluentui/react'

import { themeBMS } from './themes'
import logo from './images/Logo-Inicio-calculadoras-Reblozyl.png'
// Pages
const HomeContainer = React.lazy(() => import('./components/HomeContainer'));
const ParametrosContainer = React.lazy(() => import('./components/ParametrosContainer'));
const ReferenciasContainer = React.lazy(() => import('./components/ReferenciasContainer'));
const ResultadosContainer = React.lazy(() => import('./components/ResultadosContainer'));
const CostesFarmacosContainer = React.lazy(() => import('./components/CostesFarmacosContainer'));
const AprovechamientosVialesComponent = React.lazy(() => import('./components/AprovechamientoVialesContainer'));
const CalculosContainer = React.lazy(() => import('./components/CalculosContainer'));
const ConfiguracionContainer = React.lazy(() => import('./components/ConfiguracionContainer'))
const AutologinComponent = React.lazy(() => import('./components/AutologinComponent'))
// const LoginComponent = React.lazy(() => import('./components/LoginComponent'))


function App() {
  return (
    <>
    <DeviceOrientation lockOrientation={'landscape'}>
    <Orientation orientation='landscape' alwaysRender={false}>
    <div class="ms-Grid" dir="ltr" style={{paddingLeft: 0, paddingRight: 0}}>
    <BrowserRouter>
      <React.Suspense fallback={'Cargando...'}>
        <Switch>
          <Route exact path="/" name="Home" render={props => <HomeContainer {...props} />} />
          <Route exact path="/autologin" name="Home" render={props => <AutologinComponent {...props}/>} />
          {/* <Route exact path="/login" name="Login" render={props => <LoginComponent {...props}/>} /> */}
          <Route exact path="/smd" name="Calculadora Síndromes mielodisplásicos" render={props => <ParametrosContainer {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/parametros`} name="Calculadora Síndromes mielodisplásicos" render={props => <ParametrosContainer {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/resultados`} name="Calculadora Síndromes mielodisplásicos" render={props => <ResultadosContainer {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/referencias`} name="Calculadora Síndromes mielodisplásicos" render={props => <ReferenciasContainer {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/costesfarmacos`} name="Calculadora Síndromes mielodisplásicos" render={props => <CostesFarmacosContainer {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/aprovechamientosviales`} name="Calculadora Síndromes mielodisplásicos" render={props => <AprovechamientosVialesComponent {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/calculos`} name="Calculadora Síndromes mielodisplásicos" render={props => <CalculosContainer {...props} calculadora={'SMD'} />} />
          <Route path={`/smd/configuracion`} name="Calculadora Síndromes mielodisplásicos" render={props => <ConfiguracionContainer {...props} calculadora={'SMD'} />} />

          <Route exact path="/thalasemia" name="Calculadora Thalasemia" render={props => <ParametrosContainer {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/parametros`} name="Calculadora Thalasemia" render={props => <ParametrosContainer {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/resultados`} name="Calculadora Thalasemia" render={props => <ResultadosContainer {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/referencias`} name="Calculadora Thalasemia" render={props => <ReferenciasContainer {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/costesfarmacos`} name="Calculadora Thalasemia" render={props => <CostesFarmacosContainer {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/aprovechamientosviales`} name="Calculadora Thalasemia" render={props => <AprovechamientosVialesComponent {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/calculos`} name="Calculadora Thalasemia" render={props => <CalculosContainer {...props} calculadora={'Thalasemia'} />} />
          <Route path={`/thalasemia/configuracion`} name="Calculadora Thalasemia" render={props => <ConfiguracionContainer {...props} calculadora={'Thalasemia'} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
    </div>
    <Footer/>
    </Orientation>
    <Orientation orientation='portrait' alwaysRender={false}>
      <ThemeProvider applyTo="body" theme={themeBMS} style={{paddingTop: 10, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <div class="ms-Grid" dir="ltr" style={{paddingLeft: 0, paddingRight: 0, textAlign: 'center'}}>
          <img src={logo} alt="logoBMS" style={{ width: '60%',maxWidth: 343, maxHeight: 119, marginTop: 100 }} />
          <p style={{fontSize: 25, padding: 10}}>Coloque el dispositivo de forma horizontal para poder continuar con su uso.</p>
        </div>
      </ThemeProvider>
    </Orientation>
    </DeviceOrientation>
    </>
    
  );
}

export default App;